import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useParams} from 'react-router-dom';
import logo from '../assets/images/logo.png';
import hoja from "../assets/images/hoja.jpg";

// Configuración de Supabase
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY ;
const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

const BASE_URL = process.env.REACT_APP_BASE_URL;


export function Recovery() {
    const { email: emailParam } = useParams();
    const [email, setEmail] = useState(emailParam || '');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const updateUserInBackend = async (email, newPassword) => {
        try {
            const response = await fetch(`${BASE_URL}users/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password: newPassword }),
            });

            if (!response.ok) {
                throw new Error('Error actualizando la contraseña en el backend');
            }

            const updatedUser = await response.json();
            return updatedUser;
        } catch (error) {
            console.error('Error actualizando usuario en backend:', error);
            throw error;
        }
    };

    const updatePasswordInSupabase = async (newPassword) => {
        try {
            const { data, error } = await supabase.auth.updateUser({
                password: newPassword,
            });

            if (error) throw error;
            console.log('Contraseña cambiada en Supabase');
        } catch (error) {
            console.error('Error al cambiar la contraseña en Supabase:', error);
            throw new Error(error.message);
        }
    };

    const handlePasswordRecovery = async () => {
        if (!email) {
            alert('Por favor, ingresa tu correo electrónico');
            return;
        }
        if (!newPassword || !confirmPassword) {
            alert('Por favor, ingresa la nueva contraseña en ambos campos');
            return;
        }
        if (newPassword !== confirmPassword) {
            alert('Las contraseñas no coinciden');
            return;
        }

        try {
            // Actualizar en Supabase
            await updatePasswordInSupabase(newPassword);
            // Actualizar en el backend
            await updateUserInBackend(email, newPassword);
            alert('Contraseña actualizada exitosamente');
        } catch (error) {
            alert(`No se pudo actualizar la contraseña: ${error.message}`);
        }
    };

    return (
        <div style={styles.container} className='w-full h-full bg-white z-10 flex flex-col justify-center items-center relative'>
            <img 
                src={hoja} 
                alt="Fondo decorativo" 
                className="absolute top-0 left-0 h-full w-full object-cover opacity-20" 
            />
            <div className="text-center z-20 p-6">
            <img 
                src={logo} 
                alt="Logo" 
                className="mx-auto mb-8 w-32 h-32 "
            />
            <h2 style={styles.title}>Recuperar Contraseña</h2>
            <input
                style={styles.input}
                type="email"
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                style={styles.input}
                type="password"
                placeholder="Nueva Contraseña"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
                style={styles.input}
                type="password"
                placeholder="Confirmar Nueva Contraseña"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button style={styles.button} onClick={handlePasswordRecovery}>
                Recuperar Contraseña
            </button>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#FFFFFF',
    },
    title: {
        fontSize: '32px',
        color: '#F166AA',
        marginBottom: '20px',
    },
    input: {
        width: '80%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    button: {
        width: '80%',
        padding: '15px',
        backgroundColor: '#F166AA',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
    },
};

