import React, { useState } from "react";
import app1 from "../assets/images/app1.png";
import app2 from "../assets/images/app2.png";
import qr from "../assets/images/appios.svg";

export function AppDownload() {
    const [showModal, setShowModal] = useState(false);

    const handleIOSDownload = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <section id="app" className="min-h-screen w-full flex flex-col bg-gradient-to-r from-blue-50 via-white to-blue-50 py-16">
                <div className="container mx-auto flex flex-col items-center md:justify-between md:items-start">
                    {/* Título */}
                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear left-0 right-0
                        lg:mt-[-1rem]
                        lg:text-[3rem] 
                        md:text-black">Nuestra App</h5>
                    {/* Nuestra aplicación está disponible tanto para Android como para iOS. ¡Descárgala y disfruta de todas las funcionalidades!*/}
                    {/* Contenedor de texto e imágenes */}
                    <div className="md:flex-row container mx-auto flex flex-col items-center md:justify-between md:items-center mt-2">
                        <div className="text-center md:text-left md:w-1/3 md:ml-4 md:mt-24">
                            <p className="text-xl text-gray-700 font-medium 2xl:w-[70%] mx-auto">Nuestra aplicación está disponible tanto para Android como para iOS. ¡Descárgala y disfruta de todas las funcionalidades!</p>
                        </div>

                        {/* Imágenes */}
                        <div className="flex gap-6 mt-8 md:mt-0">
                            <img src={app1} alt="App preview 1" className="w-[18rem] rounded-lg hover:scale-105 transition-transform duration-300" />
                            <img 
                            src={app2} 
                            alt="App preview 2" 
                            className="w-[18rem] rounded-lg hover:scale-105 transition-transform duration-300 lg:hidden" 
                            />


                        </div>
                    </div>
                </div>

               
                <div className="mt-16 flex flex-col md:flex-row items-center gap-6">
                    <button
                        className="px-8 py-4 font-bold text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-500 hover:shadow-lg transition-all duration-300"
                        onClick={() => window.location.href = "https://expo.dev/accounts/tincho.laloggia/projects/semeii-app/builds/94e6f21a-36f5-4da9-9ed8-84c74211e687"}
                    >
                        Descargar para Android
                    </button>

                    <button
                        className="px-8 py-4 font-bold text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-500 hover:shadow-lg transition-all duration-300"
                        onClick={handleIOSDownload}
                    >
                        Descargar para iOS
                    </button>
                </div>
                
            
                {/* Modal */}
                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-8 w-[90%] max-w-md">
                            <h2 className="text-2xl font-bold mb-4">Pasos para descargar la app en iOS</h2>
                            <ol className="list-decimal ml-6 text-gray-700">
                                <li className="mb-2">Visita la App Store en tu dispositivo iOS.</li>
                                <li className="mb-2 ">Busca <a href="https://apps.apple.com/ar/app/expo-go/id982107779" className="text-blue-600">"Expo Go"</a> y haz clic en "Obtener".</li>
                                <li className="mb-2 ">Dentro de la app escanea el siguiente QR</li>
                                <img src={qr} alt="" />
                                <button
                                    className="px-8 py-4 font-bold text-blue-600 hover:text-blue-400"
                                    onClick={() => window.location.href = "https://expo.dev/preview/update?message=XV%20SemEII%20San%20Rafael%20App&updateRuntimeVersion=3.1.0&createdAt=2024-10-24T12%3A35%3A44.395Z&slug=exp&projectId=ed6d960c-6d43-4060-a788-b9f53c514685&group=72ab0ce4-0ec6-4a26-9278-e82041816828"}
                                >
                                    o haz click aqui
                                </button>
                               
                            </ol>
                            <div className="mt-6 flex justify-end">
                                <button
                                    className="px-6 py-2 font-bold text-white bg-blue-600 rounded-lg hover:bg-blue-500 transition-all duration-300"
                                    onClick={closeModal}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                        
                    </div>
                )}
            </section>
        </>
    );
}
