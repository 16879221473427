import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import { Menu } from './components/menu';
import { Banner } from './components/banner';
import { Nosotros } from "./components/nosotros";
import { Pilares } from "./components/pilares";
import { Disertantes } from "./components/disertantes";
import { Actividades } from "./components/actividades";
import { Sponsors } from "./components/sponsors";
import { Participar } from "./components/participar";
import { Cronograma } from './components/cronograma';
import { AppDownload } from './components/appdownload';
import { Contacto } from "./components/contacto";
import { Successful } from "./components/successful"; 
import { Recovery } from "./components/recovery";
import { DisertantesImages } from "./components/disertantesImages";

function App() {
    return (
        <Router>
            <div className="App">
                
                <Routes>
                    <Route path="/" element={
                        <>
                            <Menu />
                            <Banner />
                            <Nosotros />
                            <Pilares />
                            <Disertantes />
                            <Actividades />
                            {/*<Participar />*/}
                            <Cronograma />
                            <AppDownload />
                            
                            <Sponsors />
                            <Contacto />
                        </>
                    } />
                    <Route path="/successful" element={<Successful />} />
                    <Route path="/recovery/:email?"element={<Recovery />} />
                    <Route path="/imagesDisertantes?"element={<DisertantesImages />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
