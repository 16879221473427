import React, { useState, useEffect } from "react";
import extras from "../assets/images/Stickers SemEII_20241024_130404_0000.png";
import extras2 from "../assets/images/Leo 1 .jpg";

export function DisertantesImages() {


    return (
        <>  
            <div 
              
                className="w-[20%] h-full z-10 center m-auto mt-[-5rem] bg-color4 z-20 relative hover:w-[600%] ease-in-out duration-1000 cursor-pointer
                lg:w-full lg:text-center lg:hover:w-full lg:h-[40rem] lg:mt-[2rem]"
            >
                <img 
                    src={extras2} 
                    alt="extras" 
                   
                    className="md:flex h-[100%] m-auto center  mt-[1%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal xxl:hidden"
                />
                <img 
                    src={extras} 
                    alt="extras" 
                   
                    className="md:flex h-[100%] m-auto center  mt-[1%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal xxl:hidden"
                />
          
            </div>
        </>
    );
}
