import React, { useState, useEffect } from "react";
import talleres from "../assets/images/talleres.png";
import talleres2 from "../assets/images/talleres2.png";

export function Talleres() {
    const [see, setSee] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setSee(window.innerWidth < 767 );
        };

        // Set initial state based on the current window size
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>  
            <div 
                onMouseEnter={() => setSee(window.innerWidth > 767? true : false)}
                onMouseLeave={() => setSee(false)}
                className="w-[20%] h-full z-10 center m-auto mt-[-5rem] bg-color3 z-20 relative hover:w-[600%] ease-in-out duration-1000 cursor-pointer
                lg:w-full lg:text-center lg:hover:w-full lg:h-[40rem] lg:mt-[2rem]"
            >   
                <img 
                        src={talleres2} 
                        alt="talleres" 
                        className="hidden md:flex h-[100%] m-auto center  mt-[-10%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal"
                />
                <img 
                    src={talleres} 
                    alt="talleres" 
                    style={{ display: see ? 'block' : 'none' }} 
                    className="hidden h-[85%] m-auto center ml-[10%] mt-[1.5%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal lg:ml-0 2xl:ml-8 md:hidden md:mt-[400%] lg:hidden lg:opacity-[0]" 
                />
                
                <h5 
                    className="absolute m-auto left-0 right-0 top-[40%] bottom-0 text-[3rem] text-white font-bold z-10 animate-fade animate-ease-linear animate-duration-[1000ms] animate-normal"
                >
                    Talleres
                </h5>
            </div>
        </>
    );
}
