import React from "react";

import {ImageGallery4xl } from './imageGalery4xl';
import {ImageGallery2xl} from './imageGalery2xl';
import {ImageGallerylg} from './imageGalerylg';
import { ImageGallerysm } from "./imageGalerysm";

export function Disertantes() {


    return (
        <>  
            <section id="disertantes" className="min-h-screen w-full flex bg-white lg:h-[60rem] sm:h-[70rem]">
                <div className="w-full h-full bg-white shadow-sm z-10 center m-auto mt-[5rem]">
                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                    
                        lg:text-[4rem] 
                        md:text-black">Disertantes</h5>
                    
                    <div className="3xl:hidden">
                        <ImageGallery4xl/>
                    </div>

                    <div className="xl:hidden m2xl:hidden">
                        <ImageGallery2xl/>
                    </div>

                    <div className="hidden xl:block md:hidden">
                        <ImageGallerylg/>
                    </div>

                    <div className="mmd:hidden">
                        <ImageGallerysm/>
                    </div>
                    
                    
                    
                    

                </div>
                
                
            </section>
        </>
    );
}
