import React, { useState, useEffect } from "react";
import extras from "../assets/images/extras.png";
import extras2 from "../assets/images/extras2.png";

export function Extras() {
    const [see, setSee] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setSee(window.innerWidth < 767);
        };

        // Set initial state based on the current window size
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>  
            <div 
                onMouseEnter={() => setSee(window.innerWidth > 767 ? true : false)}
                onMouseLeave={() => setSee(false)}
                className="w-[20%] h-full z-10 center m-auto mt-[-5rem] bg-color4 z-20 relative hover:w-[600%] ease-in-out duration-1000 cursor-pointer
                lg:w-full lg:text-center lg:hover:w-full lg:h-[40rem] lg:mt-[2rem]"
            >
                <img 
                    src={extras2} 
                    alt="extras" 
                   
                    className="hidden md:flex h-[100%] m-auto center  mt-[1%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal xxl:hidden"
                />
                <img 
                    src={extras} 
                    alt="extras" 
                    style={{ display: see ? 'block' : 'none' }} 
                    className="hidden h-[80%] m-auto center ml-[10%] mt-[4%] animate-jump-in animate-duration-[1000ms] animate-ease-in animate-normal lg:ml-0 2xl:ml-0 md:hidden md:mt-[400%] lg:hidden lg:opacity-[0]" 
                />
                
                <h5 
                    className="absolute m-auto left-0 right-0 top-[40%] bottom-0 text-[3rem] text-white font-bold z-10 animate-fade animate-ease-linear animate-duration-[1000ms] animate-normal "
                >
                    Extras
                </h5>
            </div>
        </>
    );
}
