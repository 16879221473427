import React from "react";

import hoja from "../assets/images/hoja.jpg";
import logo from '../assets/images/logo.png';

export function Successful() {
    return (
        <>  
            <section id="successful" className="h-screen w-full flex bg-white">
                <div className="w-full h-full bg-white z-10 flex flex-col justify-center items-center relative">
                    
                    {/* Fondo de imagen */}
                    <img 
                        src={hoja} 
                        alt="Fondo decorativo" 
                        className="absolute top-0 left-0 h-full w-full object-cover opacity-20" 
                    />
                    
                    {/* Contenido */}
                    <div className="text-center z-20 p-6">
                        <img 
                            src={logo} 
                            alt="Logo" 
                            className="mx-auto mb-8 w-32 h-32"
                        />
                        <h1 className="text-6xl font-bold text-[#00B59A] mb-4 animate-fade">
                            ¡Éxito!
                        </h1>
                        <p className="text-xl text-gray-700 mb-8">
                            Tu correo ha sido confirmado exitosamente. Ahora puedes utilizar la app y disfrutar del XV SemEII San Rafael 2024.
                        </p>
                        <div className="bg-blue-100 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
                            <h4 className="font-bold text-2xl mb-4">¿Qué sigue?</h4>
                            <p className="text-lg">
                                Puedes comenzar explorando nuestra app, cuenta con un cronograma de actividades, información útil, mapa del complejo, asistencia y otras funcionalidades.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
