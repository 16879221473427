import React from 'react';

function TablaActividades({ actividades, color, color2 }) {
    return (
        <table className="border-collapse text-center w-[80%] mx-auto lg:w-[95%] ">
            <thead >
                <tr className="text-white" style={{ backgroundColor: color }}>
                    <th className="border px-4 py-2 pb-[1rem]">Horario</th>
                    <th className="border px-4 py-2 pb-[1rem]">Actividad</th>
                    <th className="border px-4 py-2 pb-[1rem]">Comentario</th>
                </tr>
            </thead>
            <tbody >
                {actividades.map((actividad, index) => (
                    <tr key={index} style={{ backgroundColor: color2 }}>
                        <td className="border px-4 py-2" style={{borderColor: 'white', borderWidth: '2px'}}>{actividad.horario}</td>
                        <td className="border px-4 py-2" style={{borderColor: 'white', borderWidth: '2px'}}>{actividad.actividad}</td>
                        <td className="border px-4 py-2" style={{borderColor: 'white', borderWidth: '2px'}}>{actividad.comentario}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TablaActividades;
