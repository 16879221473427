import React, { useState } from 'react';
import hoja from "../assets/images/hoja.jpg";
import TablaActividades from './tablaActividades';

export function Cronograma() {
    const actividadesDia25 = [
        { horario: '09:00-11:00', actividad: 'Acreditaciones + Desayuno + Feria de Empresas', comentario: 'Centro Integrador Universitario (CIU)' },
        { horario: '11:30-12:00', actividad: 'Acto de Apertura', comentario: 'CIU' },
        { horario: '12:00-12:30', actividad: 'Carga de equipaje', comentario: '' },
        { horario: '12:30-13:30', actividad: 'Almuerzo', comentario: '' },
        { horario: '13:30-15:45', actividad: 'Visitas Técnicas', comentario: '' },
        { horario: '15:45-16:45', actividad: 'Traslado al Centro Tecnológico', comentario: '' },
        { horario: '16:45-17:45', actividad: 'Coffee Break', comentario: 'Con muchas chatitas, claro está' },
        { horario: '17:45-18:15', actividad: 'Conferencia Profesional', comentario: 'Pablo Reyes' },
        { horario: '18:15-18:45', actividad: 'Conferencia Profesional', comentario: 'Ángel Quiles' },
        { horario: '18:45-19:00', actividad: 'Descanso', comentario: 'Para que no te nos duermas en la siguiente 😉' },
        { horario: '19:00-19:45', actividad: 'Conferencia Magistral', comentario: 'Silvio Aranguez' },
        { horario: '19:45-21:30', actividad: 'Libre', comentario: 'Para que te pongas pitico' },
        { horario: '21:30-02:00', actividad: 'Fiesta de Bienvenida', comentario: 'Bajo las estrellas ✨' }
    ];

    const actividadesDia26 = [
        { horario: "09:30-10:30", actividad: "Desayuno", comentario: "" },
        { horario: "10:30-11:30", actividad: "Conferencia Magistral", comentario: "Agustín Cerutti" },
        { horario: "11:30-12:00", actividad: "JUCOVI", comentario: "" },
        { horario: "12:00-13:00", actividad: "Almuerzo", comentario: "¡Momento perfecto para cargar energías!" },
        { horario: "13:00-14:00", actividad: "Actividad de Integración", comentario: "" },
        { horario: "14:00-14:45", actividad: "Conferencia Magistral", comentario: "Alexis Atem" },
        { horario: "14:45-15:15", actividad: "Conferencia Profesional", comentario: "Antonella Tassaroli" },
        { horario: "15:15-15:45", actividad: "Conferencia Profesional", comentario: "Ornella Bianchi" },
        { horario: "15:45-16:45", actividad: "Coffee Break", comentario: "" },
        { horario: "16:45-18:45", actividad: "Talleres", comentario: "" },
        { horario: "18:45-21:30", actividad: "Libre", comentario: "Perfecto para descansar y prepararse para la noche" },
        { horario: "21:30-05:00", actividad: "Evento Nocturno", comentario: "Cenamos y a bailar 💃🕺" }
    ];

    const actividadesDia27 = [
        { horario: "10:00-11:00", actividad: "Desayuno", comentario: "" },
        { horario: "11:00-13:00", actividad: "Actividad Mejores Ensayos", comentario: "" },
        { horario: "13:00-14:00", actividad: "Almuerzo", comentario: "" },
        { horario: "14:00-14:30", actividad: "Conferencia Profesional", comentario: "Guillermo Quirantes" },
        { horario: "14:30-15:15", actividad: "Conferencia Magistral", comentario: "Edgardo Lopez" },
        { horario: "15:15-15:30", actividad: "Descanso", comentario: "📱✏️" },
        { horario: "15:30-16:30", actividad: "Coffee Break", comentario: "" },
        { horario: "16:30-18:30", actividad: "Talleres", comentario: "" },
        { horario: "18:30-19:00", actividad: "Acto de Cierre", comentario: "" },
        { horario: "19:00-21:30", actividad: "Libre", comentario: "" },
        { horario: "21:30-??", actividad: "Cena de Gala", comentario: "Tradicional de AArEII pero con un toque sanrafaelino" }
    ];

    const [currentActivity, setCurrentActivity] = useState(actividadesDia25);
    const [color, setColor] = useState('#2CD3E2');
    const [color2, setColor2] = useState('#c9e5da');

    const handleCurrentActivity = (actividad, color, color2) => {
        setCurrentActivity(actividad);
        setColor(color);
        setColor2(color2);
    };

    return (
        <section id="cronograma" className="min-h-full w-full flex flex-col bg-white">
            <div className="w-full bg-white z-10 center m-auto mt-[5rem] relative">
                <img src={hoja} alt="" className="z-1 absolute mt-[-5%] h-full w-full object-cover opacity-20" />
                <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear absolute left-0 right-0
                        lg:mt-[-1rem]
                        lg:text-[3rem] 
                        md:text-black">Cronograma</h5>

                <div className="mt-[18%] z-10 relative">
                    <div className="flex justify-center space-x-4 mb-4">
                        <button
                            className="px-4 py-2 font-bold text-white bg-[#4cbfd2] rounded hover:bg-blue-700 focus:bg-blue-700"
                            onClick={() => handleCurrentActivity(actividadesDia25, '#4cbfd2', '#c9e5da')}
                        >
                            Día 1
                        </button>
                        <button
                            className="px-4 py-2 font-bold text-white bg-[#F166AA] rounded hover:bg-blue-700 focus:bg-blue-700"
                            onClick={() => handleCurrentActivity(actividadesDia26, '#F166AA', '#efa4c9')}
                        >
                            Día 2
                        </button>
                        <button
                            className="px-4 py-2 font-bold text-white bg-[#fab74f] rounded hover:bg-blue-700 focus:bg-blue-700"
                            onClick={() => handleCurrentActivity(actividadesDia27, '#fab74f', '#fcdac2')}
                        >
                            Día 3
                        </button>
                    </div>
                    <TablaActividades actividades={currentActivity} color={color} color2={color2} />
                    <br /><br />

                        
                        
                    <br /><br />
                </div>
            </div>
        </section>
    );
}
