import React from "react";

import sponsor1 from '../assets/sponsors/Colaborador Fundación YPF.png';
import sponsor2 from '../assets/sponsors/Sponsor ONELITE PHARMA.png';
import sponsor3 from '../assets/sponsors/Sponsor TASSAROLI.png';
import sponsor4 from '../assets/sponsors/Sponsor SUDAMERICANA DE BEBIDAS.png'
import sponsor5 from '../assets/sponsors/Sponsor TR3BOL.png';
import sponsor6 from '../assets/sponsors/Sponsor EL SECRETO.png';
import sponsor7 from '../assets/sponsors/Sponsor LA PULPERA.png';
import sponsor8 from '../assets/sponsors/Sponsor CASA NATURAL.png';
import sponsor9 from '../assets/sponsors/Sponsor DINO.jpg';
import sponsor10 from '../assets/sponsors/Sponsor MUMM.png';
import sponsor11 from '../assets/sponsors/Sponsor TRANSPARENCIA.jpg';
import sponsor12 from '../assets/sponsors/Sponsor HIERROSAN.png';
import sponsor13 from '../assets/sponsors/Sponsor Techin.png';
import sponsor14 from '../assets/sponsors/Sponsor MUNICIPALIDAD.png';
import sponsor15 from '../assets/sponsors/Sponsor PIETRELLI.png';
import sponsor16 from '../assets/sponsors/Sponsor CUI.png';
import sponsor17 from '../assets/sponsors/Sponsor CREACTIVA.png';
import sponsor18 from '../assets/sponsors/Sponsor DISTROCUYO.svg';
import sponsor19 from '../assets/sponsors/Sponsor EL NEVADO.png';
import sponsor20 from '../assets/sponsors/Sponsor BSB.jpg';
import sponsor21 from '../assets/sponsors/Sponsor JUCOVI.jpeg';
import sponsor22 from '../assets/sponsors/Sponsor M&E.webp';
import sponsor23 from '../assets/sponsors/Sponsor BIANCHI.jpg';
import sponsor24 from '../assets/sponsors/Sponsor NIDO.jpeg';
import sponsor25 from '../assets/sponsors/Sponsor FRISE.jpeg';
import sponsor26 from '../assets/sponsors/Sponsor FENIX.png';
import sponsor27 from '../assets/sponsors/Sponsor Simonassi.png';


export function Sponsors() {
    const sponsors = [
        { id: 1, name: "Colaborador Fundación YPF", img: sponsor1, url: 'https://fundacionypf.org/' },
        { id: 2, name: "ONELITE PHARMA", img: sponsor2 , url: 'https://www.onelitepharma.com/'},
        { id: 3, name: "TASSAROLI", img: sponsor3 , url: 'https://tassaroli.com.ar/'}, 
        { id: 4, name: "SUDAMERICANA DE BEBIDAS" , img: sponsor4 , url: 'https://www.sudamericanadebebidas.com/'},
        { id: 5, name: "TR3BOL ARGENTINA", img: sponsor5 , url: 'https://trebolargentina.com/'},
        { id: 6, name: "EL SECRETO", img: sponsor6 , url: 'https://linktr.ee/elsecretosanrafael'},
        { id: 7, name: "LA PULPERA", img: sponsor7 , url: 'https://inicio.pulperasanrafael.com.ar/'},
        { id: 8, name: "CASA NATURAL", img: sponsor8 , url: 'https://www.instagram.com/casa_natural.sr/'},
        {id: 9, name: "LIBRERÍA DINO", img: sponsor9 , url: 'https://www.instagram.com/libreriadino/'},
        { id: 10, name: "MUMM", img: sponsor10 , url: 'https://www.mumm.com.ar/'},
        { id: 11, name: "TRANSPARENCIA", img: sponsor11 , url: 'https://m.facebook.com/p/Transparencia-100066802964608/?locale=es_ES'},
        { id: 12, name: "HIERROSAN", img: sponsor12 , url: 'https://www.hierrosan.com/'},
        { id: 13, name: "TECHIN", img: sponsor13 , url: 'https://careers.techint.com/Techint/go/Techint-Ingenier%C3%ADa-y-Construcci%C3%B3n/3010700/'},
        { id: 14, name: "MUNICIPALIDAD", img: sponsor14 , url: 'https://www.sanrafael.gov.ar/'},
        { id: 15, name: "PIETRELLI", img: sponsor15 , url: 'https://pietrellifruit.com/planta-san-rafael/'},
        { id: 16, name: "CUI", img: sponsor16 , url: 'https://cui.edu.ar/'},
        { id: 18, name: "DISTROCUYO", img: sponsor18 , url: 'https://www.distrocuyo.com/'},
        { id: 19, name: "EL NEVADO", img: sponsor19 , url: 'https://fincaelnevado.com/'},
        { id: 20, name: "BSB", img: sponsor20 , url: 'http://bsbingenieria.com.ar/'},
        { id: 21, name: "JUCOVI", img: sponsor21 , url: 'https://acovi.com.ar/index.php/jucovi/'},
        { id: 22, name: "M&E", img: sponsor22 , url: 'https://myecourses.org/'},
        { id: 23, name: "BIANCHI", img: sponsor23 , url: 'https://www.bodegasbianchi.com.ar/'},
        { id: 24, name: "NIDO", img: sponsor24 , url: 'https://www.instagram.com/hostal_el_nido/'},
        { id: 25, name: "FRISE", img: sponsor25 , url: 'https://www.instagram.com/frisepack/?hl=es'},
        { id: 26, name: "FENIX", img: sponsor26 , url: 'https://www.instagram.com/tio.fenix/?hl=es'},
        { id: 17, name: "CREACTIVA", img: sponsor17 , url: 'https://creactiva.network/'},
        { id: 27, name: "SIMONASSI", img: sponsor27 , url: 'https://bodegasimonassilyon.com/'},
    ];

    return (
        <>  
            <section id="sponsors" className="min-h-screen w-full flex bg-white md:h-[65rem] mb-[80rem]">
                <div className="w-full h-screen bg-white z-10 center m-auto mt-[5rem]">
                    
                    <h5 className="mt-2 m-auto text-center text-[10rem] diagonal-text font-semibold z-10 animate-fade animate-ease-linear
                    
                        lg:text-[4rem] 
                        md:text-black">Sponsors</h5>

                    <div className="w-[90%] mx-auto ml-[7%] flex grid grid-cols-4 row-auto gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:w-[95%] md:ml-0 md:mt-[8rem] md:gap-6">
                        {sponsors.map((sponsor) => (
                            <div key={sponsor.id} className="m-auto w-[10rem] md:w-[65%] ">
                                <a href={sponsor.url} target="_blank" rel="noreferrer">
                                <img
                                    src={sponsor.img}
                                    alt={sponsor.name}
                                    className="m-auto w-[100%] mxl:p-6 md:w-full animate-fade animate-ease-linear cursor-pointer"
                                />
                                </a>
                            </div>
                        ))}
                    </div>
                    {/* 
                    <a href="https://wa.me/5492604513825?text=Hola%2C%20me%20gustar%C3%ADa%20ser%20Sponsor%20del%20." rel="noreferrer" target="_blank" className="mt-8  left-[42%] absolute text-2xl font-semibold bg-color2 p-4 text-white hover:border-2 hover:bg-violet-600 md:left-0 md:right-0 md:w-[70%] md:mx-auto">
                        Quiero ser Sponsor
                    </a>
                    */}
                       
                </div>
            </section>
        </>
    );
}
